import React, { createContext, useContext, useMemo } from "react";

import config from "../../config/site-config";

const ThemeContext = createContext({
  settings: {},
  page: {},
  routes: {
    homepage: "",
  },
});

const ThemeProvider = ({ children, pageData }) => {
  const { settings } = pageData;

  const value = useMemo(() => {
    const routes = {
      homepage:
        settings.lang === config.siteLang
          ? "/"
          : `/${settings.lang.split("-")[0]}/`,
    };

    return { ...pageData, routes };
  }, [pageData, settings.lang]);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

const useTheme = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error("useTheme must be used within ThemeProvider");
  }

  return context;
};

export { ThemeContext, ThemeProvider, useTheme };
